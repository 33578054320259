body {
  margin: 0;
  background-color: #121111;
  color: #BFA874;
  font-family: 'Tinos', serif;
  height: 100%;
}

html,
#root {
  height: 100%;
}

.email:hover {
  color: #E6D6B3;
  text-decoration: underline;
}

.email:active {
  color: #BFA874;
  text-decoration: underline;
}

.email {
  color: #BFA874;
  text-decoration: underline;
}

.email:visited {
  color: #BFA874;
  text-decoration: underline;
}