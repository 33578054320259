/* reset */
button {
  all: unset;
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: white;
  color: var(--violet11);
  box-shadow: 0 2px 10px var(--blackA7);
}

.SelectTrigger:hover {
  background-color: var(--mauve3);
}

.SelectTrigger:focus {
  box-shadow: 0 0 0 2px black;
}

.SelectTrigger[data-placeholder] {
  color: var(--violet9);
}

.SelectIcon {
  color: Var(--violet11);
}

.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}

.SelectItem[data-disabled] {
  color: var(--mauve8);
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--violet9);
  color: var(--violet1);
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet11);
  cursor: default;
}

/* reset */
button {
  all: unset;
}

.SwitchRoot {
  width: 42px;
  height: 25px;
  background-color: var(--blackA9);
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px var(--blackA7);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.SwitchRoot:focus {
  box-shadow: 0 0 0 2px white;
}

.SwitchRoot[data-state='checked'] {
  background-color: white;
}

.SwitchThumb {
  display: block;
  width: 21px;
  height: 21px;
  background-color: #BFA874;
  border-radius: 9999px;
  box-shadow: 0 2px 2px var(--blackA7);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}

.SwitchThumb[data-state='checked'] {
  transform: translateX(19px);
}

.Label {
  color: white;
  font-size: 15px;
  line-height: 1;
}

.react-time-picker {
  display: inline-flex;
  position: relative;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-time-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  width: 125px;
  height: 35px;
  
}

.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  background-color: black;
  border: thin solid #BFA874;
  border-radius: 5px;
}

.react-time-picker__inputGroup:hover {
  border: thin solid #b4b4ad;
}

.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
  display: inline-block;
  font-size: large;
  color: white;
}

.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  font-size: large;
  align-items: center;
  color: white;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 255, 255, 0.1);
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-time-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-time-picker__button:enabled {
  cursor: pointer;
}

.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #BFA874;
}

.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #BFA874;
}

.react-time-picker__button svg {
  display: inherit;
}

.react-time-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  z-index: 1;
}

.react-time-picker__clock--closed {
  display: none;
}
.button {
color: #BFA874;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
font-size: 24px; 
height: 100%;
cursor: pointer;
}

.active {
  text-decoration: underline;
}


