.container {
  width: 200vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: yellow;
}

.lastContainer {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.panel {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red {
  background: red;
}

.orange {
  background: orange;
}

.purple {
  background: purple;
}

.blue {
  background-color: blue;
}

.gold {
  background-color: #BFA874;
}



.pricing-plans {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  flex-direction: row;
  justify-content: center;
  width: 70vw;
  padding: 64px;
}

.pricing-plans-mobile {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  flex-direction: row;
  justify-content: center;
  width: 60vw;
  padding: 64px;
}

.pricing-card {
  --col: #e4e4e7;
  position: relative;
  min-width: 360px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 4px;
  border: 1px solid #262626;
  background-color: #00000020;
  box-shadow: 0 0 32px transparent;
  text-align: center;
}

.pricing-card-mobile {
  --col: #e4e4e7;
  position: relative;
  min-width: 300px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 4px;
  border: 1px solid #262626;
  background-color: #00000020;
  box-shadow: 0 0 32px transparent;
  text-align: center;
}

.features-card {
  --col: black;
  color: black;
  position: relative;
  min-width: 360px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 4px;
  border: 1px solid #262626;
  background-color: rgb(191, 168, 116);
  box-shadow: 0 0 32px transparent;
  text-align: center;
}

.pricing-card.basic {
  --col: #BFA874;
}

.pricing-card-mobile.basic {
  --col: #BFA874;
}

.pricing-card.standard {
  --col: #BFA874;
}

.pricing-card.premium {
  --col: #BFA874;
}

.pricing-card:hover {
  border-color: var(--col);
  background-color: #4d494998;
  box-shadow: 0 0 32px #171717;
  transform: translateY(-16px) scale(1.02);
  transition: all 0.5s ease;
}

.pricing-card-mobile:hover {
  border-color: var(--col);
  background-color: #4d494998;
  box-shadow: 0 0 32px #171717;
  transform: translateY(-16px) scale(1.02);
  transition: all 0.5s ease;
}

.pricing-card>*:not(:last-child) {
  margin-bottom: 32px;
}

.pricing-card-mobile>*:not(:last-child) {
  margin-bottom: 32px;
}

.features-card>*:not(:last-child) {
  margin-bottom: 32px;
}

.pricing-card .heading h4 {
  padding-bottom: 12px;
  color: var(--col);
  font-size: 24px;
  font-weight: normal;
}

.pricing-card-mobile .heading h4 {
  padding-bottom: 12px;
  color: var(--col);
  font-size: 24px;
  font-weight: normal;
}

.features-card .heading h4 {
  padding-bottom: 12px;
  color: var(--col);
  font-size: 24px;
  font-weight: normal;
}

.pricing-card .heading p {
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card-mobile .heading p {
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.features-card .heading p {
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .price {
  position: relative;
  color: var(--col);
  font-size: 60px;
  font-weight: bold;
}

.pricing-card-mobile .price {
  position: relative;
  color: var(--col);
  font-size: 60px;
  font-weight: bold;
}

.features-card .price {
  position: relative;
  color: var(--col);
  font-size: 60px;
  font-weight: bold;
}

.pricing-card .price sub {
  position: absolute;
  bottom: 14px;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card-mobile .price sub {
  position: absolute;
  bottom: 14px;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.features-card .price sub {
  position: absolute;
  bottom: 14px;
  color: black;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .features li {
  padding-bottom: 16px;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
}

.pricing-card-mobile .features li {
  padding-bottom: 16px;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
}

.features-card .features li {
  padding-bottom: 16px;
  color: black;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
}

.pricing-card .features li i,
.pricing-card .features li strong {
  color: #e4e4e7;
  font-size: 20px;
  text-align: left;
}

.pricing-card-mobile .features li i,
.pricing-card-mobile .features li strong {
  color: #e4e4e7;
  font-size: 20px;
  text-align: left;
}

.features-card .features li i,
.features-card .features li strong {
  color: black;
  font-size: 16px;
  text-align: left;
}

.pricing-card .features li strong {
  padding-left: 24px;
}

.pricing-card-mobile .features li strong {
  padding-left: 24px;
}

.features-card .features li strong {
  padding-left: 24px;
}

.pricing-card .cta-btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  cursor: pointer;
  padding: 12px;
  border-radius: 4px;
  font-family: inherit;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.pricing-card-mobile .cta-btn {
  position: absolute;
  bottom: 32px;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  font-family: inherit;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.features-card .cta-btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.pricing-card .cta-btn:active {
  background-color: transparent;
  color: var(--col);
  transition: all 0.3s ease;
}

.pricing-card-mobile .cta-btn:active {
  background-color: transparent;
  color: var(--col);
  transition: all 0.3s ease;
}

.features-card .cta-btn:active {
  background-color: transparent;
  color: var(--col);
  transition: all 0.3s ease;
}

.startButton {
  position: relative;
  border: 0;
  display: inline-block;
  width: 12rem;
  height: auto;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  background: transparent;
}

.starttext {
  position: absolute;
  inset: 0;
  padding: 0. 9rem 0;
  margin-left: 1.85rem;
  color: #f82936;
  line-height: 1.6;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

.circle {
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #f82936;
  border-radius: 1.625rem;
  transition: all 0.45s ease-in-out;
}

.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.arrow {
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
  transition: all 0.4s ease-in-out;
}

.arrow::before {
  content: "";
  position: absolute;
  top: -0.3rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125em solid #fff;
  border-right: 0.125rem solid■#fff;
  transform: rotate(45deg);
}

.startButton:hover .circle {
  width: 100%;
}

.startButton:hover .arrow {
  background: #fff;
  transform: translateX(1rem);
}

.startButton:hover .starttext {
  color: #fff;
}